import EventEmitter from 'eventemitter3';
import { useEffect, useState } from 'react';

import {
  DEFAULT_LOCAL_STORAGE_VALIDATORS,
  DEFAULT_LOCAL_STORAGE_VALUES,
  LocalStorageItems,
  LocalStorageKey
} from '~/constants';

const LocalSettingsEE = new EventEmitter<LocalStorageKey>();

// Get local storage value to initialize state
const getStoredLocalStorageValue = (key: LocalStorageKey) => {
  // Check for window to avoid SSR issues
  const localStorageValue =
    typeof window !== 'undefined'
      ? window.localStorage.getItem(key)
      : DEFAULT_LOCAL_STORAGE_VALUES[key];

  const validator = DEFAULT_LOCAL_STORAGE_VALIDATORS[key];
  const isValid = validator(localStorageValue);

  if (!isValid) {
    console.error(
      `Local storage value for ${key} is invalid: ${localStorageValue}. Setting to default: ${DEFAULT_LOCAL_STORAGE_VALUES[key]}`
    );
    localStorage.setItem(key, DEFAULT_LOCAL_STORAGE_VALUES[key]);
  }

  return isValid ? localStorageValue : DEFAULT_LOCAL_STORAGE_VALUES[key];
};

const useLocalStorage = <K extends LocalStorageKey>(
  key: K
): [LocalStorageItems[K], (value: LocalStorageItems[K]) => void] => {
  // Set state to default value
  // Let useEffect set local storage value on mount
  // to avoid issues with SSR
  const [value, setValue] = useState<LocalStorageItems[K]>(
    getStoredLocalStorageValue(key) as LocalStorageItems[K]
  );

  const setter = (newVal: LocalStorageItems[K]) => {
    // Remove from local storage if undefined
    if (newVal === undefined) {
      localStorage.removeItem(key);
      return;
    }

    // Set local storage if valid
    const validator = DEFAULT_LOCAL_STORAGE_VALIDATORS[key];
    const isValid = validator(newVal);

    if (isValid) {
      localStorage.setItem(key, newVal.toString());
      setValue(newVal);
      LocalSettingsEE.emit(key);
    } else {
      console.error(`Invalid value for ${key}: ${newVal}`);
    }
  };

  // Listen for changes to local storage
  useEffect(() => {
    const listener = () => {
      const storedValue = getStoredLocalStorageValue(key);
      setValue(storedValue as LocalStorageItems[K]);
    };

    LocalSettingsEE.on(key, listener);
    return () => {
      LocalSettingsEE.off(key, listener);
    };
  }, [key]);

  return [value, setter];
};
export default useLocalStorage;
