import _styled from "styled-components";
import { DisplayedChatMessage, ResponseChatMessage } from '../Chat.types';
import BatchResponseMessage from './BatchResponseMessage';
import BatchUserMessage from './BatchUserMessage';
const BatchContainer = _styled.div.withConfig({
  displayName: "ChatMessageBatch__BatchContainer",
  componentId: "sc-j452a0-0"
})({
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.75rem"
});
const BatchMessagesContainer = _styled.div.withConfig({
  displayName: "ChatMessageBatch__BatchMessagesContainer",
  componentId: "sc-j452a0-1"
})({
  "gap": "0px"
});
const BatchMessage = _styled.div.withConfig({
  displayName: "ChatMessageBatch__BatchMessage",
  componentId: "sc-j452a0-2"
})(({
  $isFirstResponse
}) => [{
  "display": "flex",
  "flexDirection": "column"
}, !$isFirstResponse && {
  "borderTopWidth": "2px",
  "--tw-border-opacity": "0.1",
  "borderColor": "rgb(29 78 216 / var(--tw-border-opacity))"
}]);
type ChatMessageBatchProps = {
  batchResponseMessages: ResponseChatMessage[];
  message: DisplayedChatMessage;
};
const ChatMessageBatch = ({
  message,
  batchResponseMessages
}: ChatMessageBatchProps) => <BatchContainer>
    <BatchUserMessage chatMessage={message} />
    <BatchMessagesContainer>
      {batchResponseMessages.map((response, index) => <BatchMessage key={response.id} $isFirstResponse={index === 0}>
          <BatchResponseMessage key={response.id} chatMessage={response} fileNumber={index + 1} totalBatchedResponses={batchResponseMessages.length} />
        </BatchMessage>)}
    </BatchMessagesContainer>
  </BatchContainer>;
export default ChatMessageBatch;