import _styled from "styled-components";
import { ChatMessageRole, ChatMessageState, PusherEvent, getPusherChatChannel } from '@kindo/universal';
import { debounce } from 'lodash';
import Pusher from 'pusher-js';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { FileResource } from './AddFileModal';
import { DisplayedChatMessage, isResponseChatMessage, isUserChatMessage } from './Chat.types';
import ChatLoadingIndicator from './ChatLoadingIndicator';
import { chatItemToDisplayedChatMessage } from './ChatMessage.utils';
import { ChatMessageBatch } from './ChatMessageBatch';
import { ResponseChatMessage } from './ResponseChatMessage';
import { UserChatMessage } from './UserChatMessage';
import { SlackMessageSharingProvider } from '~/hooks';
import { nextTrpc } from '~/trpc';
const ChatMessagesContainer = _styled.div.withConfig({
  displayName: "ChatMessages__ChatMessagesContainer",
  componentId: "sc-1hmadfs-0"
})({
  "display": "flex",
  "height": "100%",
  "width": "100%",
  "flexDirection": "column",
  "gap": "0.75rem",
  "overflowY": "auto",
  "overflowX": "hidden"
});
type ChatMessagesProps = {
  chatId: string;
  chatMessages: DisplayedChatMessage[];
  chatTitle: string;
  isUsingTools: boolean;
  sendMessage: (args: {
    message: string;
    selectedFiles: FileResource[];
    urls: string[];
  }) => Promise<void>;
  setChatMessages: Dispatch<SetStateAction<DisplayedChatMessage[]>>;
  waitingForChatAnswer: boolean;
};

/**
 * Fetches and renders messages for provided chat inside Workstation
 */
const ChatMessages: React.FC<ChatMessagesProps> = ({
  chatId,
  chatMessages,
  isUsingTools,
  setChatMessages,
  waitingForChatAnswer,
  sendMessage
}) => {
  // State
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);
  const [loadingIndicatorText, setLoadingIndicatorText] = useState('Thinking...');
  const bottomOfChatRef = useRef<HTMLDivElement>(null);

  // Queries
  const {
    data: chatData,
    refetch: refetchChatMessages
  } = nextTrpc.chats.getChatMessages.useQuery({
    chatId
  }, {
    refetchInterval: waitingForChatAnswer ? 5000 : false,
    // Select will run on every render without being wrapped in useCallback
    select: useCallback(data => {
      const transformedData = data.items.map(chatItemToDisplayedChatMessage);
      // When an response is now not pending, it means the chat was completed or errored.
      // This can be be run from either the Pusher complete event
      // or from polling (which prevents a Pusher failure from breaking the chat experience).
      const answerWasCompleted = transformedData.some(message => {
        const existingMessage = chatMessages.find(m => m.id === message.id);
        return existingMessage && existingMessage.role === ChatMessageRole.ASSISTANT && message.state !== ChatMessageState.PENDING && existingMessage.state === ChatMessageState.PENDING;
      });

      // If there are no messages,a new answer message was created,
      // or the chat completion was not caught
      // override the chat messages with the polled data.
      if (transformedData.length > chatMessages.length || answerWasCompleted) {
        setChatMessages(transformedData);
      }
      return transformedData;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setChatMessages, chatMessages.length]),
    refetchOnWindowFocus: false
  });

  // Set initial chatMessages on load
  useEffect(() => {
    if (chatMessages.length === 0 && (chatData?.length ?? 0) > 0) {
      setChatMessages(chatData ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatData?.length]);

  // When no longer waiting for chat answer, stop streaming message
  useEffect(() => {
    if (chatMessages?.length > 0 && !waitingForChatAnswer) {
      setChatMessages(messages => messages.map(message => ({
        ...message,
        isStreaming: false
      })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitingForChatAnswer, chatMessages?.length, setChatMessages]);

  /**
   * Streaming code below to run when chatId changes:
   *   - Create a pusher connection
   *   - Subscribe to message streaming channel
   *   - Handlers for delta, completion, and error events
   *   - When component unmounts, disconnect Pusher connection
   */
  useEffect(() => {
    /**
     * Create a pusher connection
     * https://pusher.com/docs/channels/using_channels/connection/
     *
     * We must create the connection here, and not on app load, since
     * if a channel is not subscribed to within 30 seconds, the authorized connection
     * will be disconnected.
     * https://pusher.com/blog/authorized-connections-is-out-of-beta/#how-do-i-enable-authorized-connections
     */
    const pusher = new Pusher(process.env.PUSHER_KEY!, {
      cluster: 'us3',
      authEndpoint: '/api/um/proxy/pusher-auth'
    });

    // Subscribe to chat channel
    const channelName = getPusherChatChannel(chatId);
    const channel = pusher.subscribe(channelName);

    // Handle Pusher Error event
    // These errors should also be saved on the chat message itself,
    // so we don't need to show a toast or do anything else here.
    channel.bind(PusherEvent.ERROR, (error: any) => {
      console.error(error);
      refetchChatMessages();
    });

    // Handle Pusher Complete event
    channel.bind(PusherEvent.COMPLETE, () => {
      refetchChatMessages();
    });

    // Handle Pusher Processing New Message event
    channel.bind(PusherEvent.PROCESSING_NEW_MESSAGE, () => {
      setLoadingIndicatorText('Thinking...');
      refetchChatMessages();
    });
    channel.bind(PusherEvent.SET_MESSAGE, (data: {
      chatMessageId: string;
      message: string;
    } | undefined) => {
      if (!data) {
        console.error('Failed to set chat message: No data provided');
        return;
      }

      // Set the updated chat messages
      setChatMessages(prev => prev.map(message => {
        if (message.id === data.chatMessageId) {
          return {
            ...message,
            message: data.message
          };
        }
        return message;
      }));
    });

    // Handle Pusher Loading Indicator Update event
    channel.bind(PusherEvent.CHAT_LOADING_INDICATOR_UPDATE, (data: {
      text: string;
    } | undefined) => {
      if (!data) {
        console.error('Failed to update thinking text: No data provided');
        return;
      }

      // Update the ChatLoadingIndicator text
      setLoadingIndicatorText(data.text);
    });

    // Handle Pusher Delta event
    channel.bind(PusherEvent.DELTA, (data: {
      accumulatedResponse: string;
      chatMessageId: string;
      delta: string;
    } | undefined) => {
      if (!data) {
        console.error('Failed to stream chat message: No data provided');
        return;
      }

      // Set the updated chat messages
      setChatMessages(prev => prev.map(message => {
        if (message.id === data.chatMessageId && message.state !== ChatMessageState.STOPPED) {
          return {
            ...message,
            message: message.message + data.delta,
            isStreaming: true
          };
        }
        return message;
      }));
    });

    // When component unmounts, disconnect Pusher connection
    return () => {
      pusher.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId, isAutoScrollEnabled]);

  /**
   * When user scrolls up, terminate auto-scrolling:
   *   - Add event listener for scroll events in chat container
   *   - If user scrolls up, set isAutoScrollEnabled to false
   *   - Manage listener cleanup on chat container unmount
   */
  const chatContainerRef = useCallback((element: HTMLDivElement) => {
    let prevScrollTop = 0;

    // Handle user up scroll event
    const handleUserScroll = () => {
      if (element) {
        const currentScrollTop = element.scrollTop;
        if (currentScrollTop < prevScrollTop) {
          setIsAutoScrollEnabled(false);
        }
        prevScrollTop = currentScrollTop;
      }
    };

    // Add event listener for scroll events in chat container
    if (element) {
      element.addEventListener('scroll', handleUserScroll);
    }

    // Manage listener cleanup on chat container unmount
    return () => {
      if (element) {
        element.removeEventListener('scroll', handleUserScroll);
      }
    };
  }, []);

  /**
   * Regenerate an answer message by re-sending the most recent question before it
   */
  const handleRegenerate = (message: DisplayedChatMessage) => {
    // Index of the message be regenerated
    const answerIndex = chatMessages.findIndex(m => m.id === message.id);
    const questionToResend = chatMessages[answerIndex - 1];
    if (!answerIndex || !questionToResend || questionToResend.role !== ChatMessageRole.USER) {
      console.error('Failed to regenerate chat message: Could not find question to resend');
      return;
    }
    void sendMessage({
      message: questionToResend.message,
      selectedFiles: [],
      urls: []
    });
  };
  const isUserAtBottom = () => {
    const chatContainer = bottomOfChatRef.current?.parentElement;
    if (!chatContainer) return false;
    const {
      scrollTop,
      scrollHeight,
      clientHeight
    } = chatContainer;
    return scrollHeight - scrollTop <= clientHeight + 10; // Allow a small buffer
  };
  const debouncedScrollToBottom = debounce(() => {
    if (bottomOfChatRef.current && !isUserAtBottom()) {
      bottomOfChatRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });
    }
    // This debounce amount is arbitrary, we can tweak and
    // see how it affects responsiveness and performance
  }, 40);

  // Scroll to the bottom of the chat when it begins waiting for an answer
  useEffect(() => {
    const lastMessage = chatMessages[chatMessages.length - 1];
    if (waitingForChatAnswer && !(lastMessage && isResponseChatMessage(lastMessage) && lastMessage.isStreaming)) {
      debouncedScrollToBottom();
    }

    // When the chat is waiting for an answer, enable auto-scrolling
    setIsAutoScrollEnabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitingForChatAnswer]);
  const renderChatMessage = (message: DisplayedChatMessage): React.ReactNode => {
    /**
     * For a batch of messages, we only want to render one instance for the whole batch
     * since it displays all messages. To ensure this,
     * we'll only render it for the first response message
     * in the batch, and ignore the rest.
     */

    const batchId = message.batch?.batchId;
    const batchResponseMessages = chatMessages.filter(m => m.role === ChatMessageRole.ASSISTANT && m.batch?.batchId === batchId);
    const isFirstBatchedMessage = message.id === batchResponseMessages[0]?.id;
    switch (true) {
      /** Batched messages */
      // First response message of a batch
      case isFirstBatchedMessage && batchId !== undefined:
        return <ChatMessageBatch batchResponseMessages={batchResponseMessages.filter(isResponseChatMessage)} message={message} />;
      // Batched message that isn't first response,
      // render nothing since entire batch is rendered above for first message
      case batchId && !isFirstBatchedMessage:
        return null;

      /** Non-batched messages */
      // User chat message
      case isUserChatMessage(message):
        return <UserChatMessage key={message.id} chatMessage={message} refetchChatMessages={refetchChatMessages} />;
      // Loading indicator - Pending response message that's empty
      case isResponseChatMessage(message) && message.state === ChatMessageState.PENDING && !message.message:
        return <ChatLoadingIndicator isUsingTools={isUsingTools} loadingText={loadingIndicatorText} />;
      // Response message
      case isResponseChatMessage(message):
        return <ResponseChatMessage key={message.id} chatMessage={message} onRegenerate={() => handleRegenerate(message)} onStreamChange={() => {
          if (isAutoScrollEnabled) {
            debouncedScrollToBottom();
          }
        }} />;
      default:
        return null;
    }
  };
  if (!chatMessages.length) return null;
  return <SlackMessageSharingProvider>
      <ChatMessagesContainer ref={chatContainerRef}>
        {chatMessages.map(renderChatMessage)}
        {/* Div used to scroll to bottom of chat message container */}
        <div ref={bottomOfChatRef} />
      </ChatMessagesContainer>
    </SlackMessageSharingProvider>;
};
export default ChatMessages;