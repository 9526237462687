// TODO: Clarify and rename the sizes
// What does fill mean?
export enum CardSize {
  EXPANDED = 'EXPANDED',
  FILL = 'FILL',
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
}
export enum CardPadding {
  DEFAULT = 'DEFAULT',
  WITH_LEFT_DRAWER = 'WITH_LEFT_DRAWER',
}
export enum CardOpacity {
  OPAQUE = 'OPAQUE',
  SEMI_TRANSPARENT = 'SEMI_TRANSPARENT',
}