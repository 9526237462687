import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { CHAT_AGENT_TOOLS, ContentMode, FeatureFlags, FileType, isImageFileType, Tool, TOOL_DESCRIPTIONS, TOOL_DISPLAY_NAMES } from '@kindo/universal';
import { useFlag } from '@unleash/nextjs';
import { useEffect, useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import useAutoGrow from '../../hooks/useAutoGrow';
import { FileResource } from '../Chat/AddFileModal';
import { Chip, Color, Icon, Icons, LoadingSpinner, LoadingSpinnerSize, Select, Size, Toggle, ToolTip, Typography, TypographySize } from '../core';
import { Dropdown } from '../Dropdown';
import { StaticFilePreview } from '../Library/components/FilesTab/components/FileCards/FileCard/StaticFilePreview';
import { ModelSelect } from '../shared/ModelSelect';
import { AddFilesButton } from './AddFilesButton';
import { LoadingStopButton } from './LoadingStopButton';
import { LocalStorageKey } from '~/constants';
import { useAppSelector } from '~/hooks';
import useLocalStorage from '~/hooks/useLocalStorage';
import { chatActions } from '~/redux/reducers/chatSlice';
import { Workflow } from '~/types';
const PromptBarContainer = _styled.div.withConfig({
  displayName: "PromptBar__PromptBarContainer",
  componentId: "sc-dxubo3-0"
})({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "0.5rem"
});
const PromptBarInnerContainer = _styled.div.withConfig({
  displayName: "PromptBar__PromptBarInnerContainer",
  componentId: "sc-dxubo3-1"
})(({
  disabled
}) => [{
  "position": "relative",
  "width": "100%",
  "borderRadius": "1.5rem",
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(230 232 239 / var(--tw-border-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(253 252 251 / var(--tw-bg-opacity))"
}, disabled && {
  "cursor": "not-allowed"
}]);
const ModelSelectAndToggles = _styled.div.withConfig({
  displayName: "PromptBar__ModelSelectAndToggles",
  componentId: "sc-dxubo3-2"
})({
  "display": "flex",
  "alignItems": "center",
  "gap": "1rem"
});
const ToggleContainer = _styled.div.withConfig({
  displayName: "PromptBar__ToggleContainer",
  componentId: "sc-dxubo3-3"
})({
  "display": "flex",
  "gap": "0.5rem"
});
const ToolsContainer = _styled.div.withConfig({
  displayName: "PromptBar__ToolsContainer",
  componentId: "sc-dxubo3-4"
})({
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.5rem"
});
const PromptBarFlexContainer = _styled.div.withConfig({
  displayName: "PromptBar__PromptBarFlexContainer",
  componentId: "sc-dxubo3-5"
})({
  "position": "relative",
  "display": "flex",
  "height": "100%",
  "flexDirection": "row",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "0.5rem",
  "paddingLeft": "0.5rem",
  "paddingRight": "0.5rem"
});
const SelectedFilesContainer = _styled.div.withConfig({
  displayName: "PromptBar__SelectedFilesContainer",
  componentId: "sc-dxubo3-6"
})({
  "position": "relative",
  "display": "flex"
});
const SelectedFilesHoverGroup = _styled.div.withConfig({
  displayName: "PromptBar__SelectedFilesHoverGroup",
  componentId: "sc-dxubo3-7"
})({
  "display": "flex",
  "flexDirection": "row",
  "gap": "0.5rem"
});
const WithOthersContainer = _styled.div.withConfig({
  displayName: "PromptBar__WithOthersContainer",
  componentId: "sc-dxubo3-8"
})({
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "space-between",
  "> :not([hidden]) ~ :not([hidden])": {
    "--tw-space-x-reverse": "0",
    "marginRight": "calc(0.5rem * var(--tw-space-x-reverse))",
    "marginLeft": "calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))"
  },
  "overflow": "hidden",
  "textOverflow": "ellipsis",
  "borderRadius": "9999px",
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(230 232 239 / var(--tw-border-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(224 232 233 / var(--tw-bg-opacity))",
  "paddingLeft": "0.5rem",
  "paddingRight": "0.5rem",
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem",
  "fontSize": "0.75rem",
  "lineHeight": "1rem",
  "fontWeight": "468",
  "--tw-text-opacity": "1",
  "color": "rgb(77 114 128 / var(--tw-text-opacity))",
  "&:hover": {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(77 114 128 / var(--tw-border-opacity))"
  }
});
var _StyledWithOthersContainer = _styled(WithOthersContainer).withConfig({
  displayName: "PromptBar___StyledWithOthersContainer",
  componentId: "sc-dxubo3-9"
})({
  "transitionProperty": "opacity",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "150ms",
  ".group\\/promptbar-files:hover &": {
    "opacity": "0"
  }
});
const FileChipContainer = _styled.div.withConfig({
  displayName: "PromptBar__FileChipContainer",
  componentId: "sc-dxubo3-10"
})({
  "marginBottom": "0.25rem"
});
const AdditionalFilesContainer = _styled.div.withConfig({
  displayName: "PromptBar__AdditionalFilesContainer",
  componentId: "sc-dxubo3-11"
})({
  "position": "absolute",
  "bottom": "0px",
  "marginBottom": "2.25rem",
  "display": "none",
  "flexDirection": "column",
  "gap": "0.5rem"
});
var _StyledAdditionalFilesContainer = _styled(AdditionalFilesContainer).withConfig({
  displayName: "PromptBar___StyledAdditionalFilesContainer",
  componentId: "sc-dxubo3-12"
})({
  ".group\\/promptbar-files:hover &": {
    "display": "block"
  }
});
const PromptContainer = _styled.div.withConfig({
  displayName: "PromptBar__PromptContainer",
  componentId: "sc-dxubo3-13"
})({
  "display": "flex",
  "flex": "1 1 0%",
  "alignItems": "center",
  "justifyContent": "space-between",
  "gap": "0.5rem"
});
const AdvancedChatToolsButton = _styled.div.withConfig({
  displayName: "PromptBar__AdvancedChatToolsButton",
  componentId: "sc-dxubo3-14"
})({
  "display": "flex",
  "flexDirection": "row",
  "alignItems": "center",
  "gap": "0.25rem",
  "paddingLeft": "0.5rem",
  "paddingRight": "0.5rem"
});
const PromptSubmitButton = _styled.button.withConfig({
  displayName: "PromptBar__PromptSubmitButton",
  componentId: "sc-dxubo3-15"
})(({
  disabled
}) => [{
  "height": "100%",
  "cursor": "pointer",
  "alignItems": "center"
}, disabled && {
  "cursor": "not-allowed"
}]);
const StyledTextArea = _styled.textarea.withConfig({
  displayName: "PromptBar__StyledTextArea",
  componentId: "sc-dxubo3-16"
})(({
  $empty,
  disabled = false
}) => [
// text-base is 16px, so mobile doesn't zoom in
{
  "marginTop": "0.75rem",
  "marginBottom": "0.75rem",
  "flex": "1 1 0%",
  "cursor": "text",
  "resize": "none",
  "backgroundColor": "transparent",
  "fontSize": "1rem",
  "lineHeight": "1.5rem",
  "--tw-text-opacity": "1",
  "color": "rgb(9 90 124 / var(--tw-text-opacity))",
  "outline": "2px solid transparent",
  "outlineOffset": "2px"
}, disabled && {
  "cursor": "not-allowed"
}, $empty && {
  "overflow": "hidden"
}]);
const StyledDropdown = _styled(Dropdown).withConfig({
  displayName: "PromptBar__StyledDropdown",
  componentId: "sc-dxubo3-17"
})(({
  $hasEnabledTools
}) => [$hasEnabledTools && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(220 252 231 / var(--tw-bg-opacity))"
}]);
interface PromptBarProps {
  maxHeight: number;
  onStopGeneration: () => void;
  onSubmit: (args: {
    message: string;
    selectedFiles: FileResource[];
  }) => void;
  autoFocus?: boolean;
  disableTextInput?: boolean;
  disabledSubmit?: boolean;
  loading?: boolean;
  placeholder?: string;
  tooltip?: string | undefined;
  workflow?: Workflow | undefined;
}
export const PromptBar = ({
  onSubmit,
  onStopGeneration,
  disabledSubmit = false,
  disableTextInput = false,
  placeholder = 'Type to chat, find, or create anything... ',
  loading = false,
  maxHeight,
  autoFocus = false,
  tooltip,
  workflow
}: PromptBarProps) => {
  // State
  const [value, setValue] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<FileResource[]>([]);
  // Redux
  const dispatch = useDispatch();
  const setContentMode = (contentMode: ContentMode) => dispatch(chatActions.setContentMode({
    contentMode
  }));
  const setHasMarkdownFormatting = (hasMarkdownFormatting: boolean) => dispatch(chatActions.setHasMarkdownFormatting({
    hasMarkdownFormatting
  }));
  const {
    contentMode,
    hasMarkdownFormatting
  } = useAppSelector(state => state.chat);
  const setSelectedFilesAndContentMode = (newSelectedFiles: FileResource[]) => {
    setSelectedFiles(newSelectedFiles);
    if (newSelectedFiles.length === 0 || newSelectedFiles.filter(file => file.fileType === FileType.CSV).length === 0) {
      setContentMode(ContentMode.ALL);
    }
  };

  // Ref
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  // Custom Hooks
  const [chatModel, setChatModel] = useLocalStorage<LocalStorageKey.CHAT_LLM>(LocalStorageKey.CHAT_LLM);
  const [enabledTools, setEnabledTools] = useLocalStorage<LocalStorageKey.ENABLED_TOOLS>(LocalStorageKey.ENABLED_TOOLS);
  const showMarkdownToggle = useFlag(FeatureFlags.MARKDOWN_SYSTEM_PROMPT);
  useEffect(() => {
    textAreaRef.current?.focus();
  }, [selectedFiles.length]);
  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value;
    setValue(val);
  };
  // TODO: Delete this hook once we replace with TextField component,
  // which has auto-grow automatically
  useAutoGrow(textAreaRef.current, value, 0, maxHeight);
  const handleSubmit = () => {
    if (!value || loading || disabledSubmit) {
      return;
    }

    // Blur the textarea to dismiss mobile keyboard
    textAreaRef.current?.blur();
    onSubmit({
      message: value,
      selectedFiles
    });
    setValue('');
    setSelectedFilesAndContentMode([]);

    // Maintain focus on the prompt bar so user can send next message
    // Only focus if not on mobile to prevent keyboard from popping up again
    if (!isMobileOnly) {
      textAreaRef.current?.focus();
    }
  };
  const handleToolToggle = (tool: Tool) => {
    const enabledToolsArray = JSON.parse(enabledTools);
    const isToolEnabled = enabledToolsArray.includes(tool);
    if (isToolEnabled) {
      const updatedToolsArray = enabledToolsArray.filter((enabledTool: Tool) => enabledTool !== tool);
      setEnabledTools(JSON.stringify(updatedToolsArray));
    } else {
      enabledToolsArray.push(tool);
      setEnabledTools(JSON.stringify(enabledToolsArray));
    }
  };
  const handleKeyDown = (evt: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // If user presses backspace without any text, remove the last file
    if (evt.key === 'Backspace' && !evt.currentTarget.value) {
      setSelectedFilesAndContentMode(selectedFiles.slice(0, selectedFiles.length - 1));
    }

    // If user presses enter without shift, submit the form
    if (evt.key === 'Enter' && !evt.shiftKey) {
      evt.preventDefault();
      evt.stopPropagation();
      handleSubmit();
    }
  };
  const oneCsvSelected = selectedFiles.filter(file => file.fileType === FileType.CSV).length === 1;
  const areMultipleFilesSelected = selectedFiles.length > 1;
  const isContentModeSelectDisabled = !areMultipleFilesSelected && !oneCsvSelected;
  return <PromptBarContainer>
      {isMobileOnly && <ModelSelect chatModel={chatModel} onChange={setChatModel} placeholderLabel="Select AI Model to Chat" prefix="Chat Model: " />}
      {!!selectedFiles.length && <SelectedFilesContainer>
          <SelectedFilesHoverGroup className="group/promptbar-files">
            {selectedFiles.slice(0, 1)?.map(file => isImageFileType(file.fileType) ? <StaticFilePreview key={file.id} fileId={file.id} fileType={file.fileType} /> : <Chip key={file.id} endIcon={Icon.CLOSE} label={file.name} onClick={() => {
          setSelectedFilesAndContentMode(selectedFiles.filter(f => f.id !== file.id));
        }} startIcon={Icon.FILE} />)}
            {selectedFiles.length > 1 && <_StyledWithOthersContainer>
                <span>with {selectedFiles.length - 1} others</span>
              </_StyledWithOthersContainer>}
            <_StyledAdditionalFilesContainer>
              {selectedFiles.slice(1).map(file => <FileChipContainer key={file.id}>
                  <Chip key={file.id} endIcon={Icon.CLOSE} label={file.name} onClick={() => {
              setSelectedFilesAndContentMode(selectedFiles.filter(f => f.id !== file.id));
            }} startIcon={Icon.FILE} />
                </FileChipContainer>)}
            </_StyledAdditionalFilesContainer>
          </SelectedFilesHoverGroup>
        </SelectedFilesContainer>}
      <PromptBarInnerContainer disabled={disabledSubmit}>
        <ToolTip content={tooltip ?? ''}>
          <PromptBarFlexContainer>
            {!isMobileOnly && <AddFilesButton data-id="workflow_plus" disabled={disabledSubmit} onFileSelect={newSelected => {
            setSelectedFilesAndContentMode(newSelected);
            textAreaRef.current?.focus();
          }} selectedFiles={selectedFiles} />}
            <PromptContainer>
              {/* TODO: Replace this with TextField component */}
              <StyledTextArea ref={textAreaRef} $empty={!value} autoFocus={autoFocus} disabled={disableTextInput} name="query" onChange={handleChange} onKeyDown={handleKeyDown} placeholder={selectedFiles.length ? '' : placeholder} required rows={1} value={value} />
              {!loading && <PromptSubmitButton disabled={disabledSubmit} onClick={handleSubmit}>
                  <Icons color={disabledSubmit || !textAreaRef.current?.value ? Color.NEUTRAL_LIGHT : Color.NAVY} icon={Icon.SEND} size={Size.LARGE} />
                </PromptSubmitButton>}
              {/* If workflow is not defined, then we are in chat mode */}
              {loading && !workflow && <LoadingStopButton onStopClick={onStopGeneration} />}
              {/* If workflow is defined, then we are in workflow mode */}
              {/* TODO: Add capability for stopping workflow */}
              {loading && workflow && <LoadingSpinner size={LoadingSpinnerSize.LARGE} />}
            </PromptContainer>
          </PromptBarFlexContainer>
        </ToolTip>
      </PromptBarInnerContainer>
      {!isMobileOnly && <ModelSelectAndToggles>
          <ModelSelect chatModel={chatModel} onChange={setChatModel} placeholderLabel="Select AI Model to Chat" prefix="Chat Model: " />
          <Select<ContentMode> disabled={isContentModeSelectDisabled} onChange={(val: ContentMode) => {
        setContentMode(val);
      }} options={[{
        label: 'For all files',
        value: ContentMode.ALL,
        tooltip: 'Get one response for all files.\nAll rows of the CSV are used at once.'
      }, {
        disabled: !areMultipleFilesSelected,
        label: 'On each file',
        value: ContentMode.PARALLEL,
        tooltip: 'Get a response for each file'
      }, {
        disabled: !oneCsvSelected,
        label: 'CSV Row-By-Row',
        value: ContentMode.CSV_ROW_BY_ROW,
        tooltip: 'Get a response for each row in the CSV.\nAdditional attached files will be used as context.'
      }]} placeholderLabel="Select single response for all files, for each file, or for each row in a CSV" tooltip={isContentModeSelectDisabled ? 'Select multiple files to switch file chat mode' : ''} value={contentMode} />
          <StyledDropdown $hasEnabledTools={JSON.parse(enabledTools).length > 0} trigger={<AdvancedChatToolsButton>
                <Icons color={Color.NAVY} icon={Icon.BOLT} />
                <Typography color={Color.NAVY} size={TypographySize.SMALL}>
                  Advanced Chat Tools
                </Typography>
              </AdvancedChatToolsButton>}>
            <ToolsContainer>
              {CHAT_AGENT_TOOLS.map((tool: Tool) => <ToolTip key={tool} content={TOOL_DESCRIPTIONS[tool]}>
                  <ToggleContainer key={tool}>
                    <Toggle checked={JSON.parse(enabledTools).includes(tool)} onToggle={() => handleToolToggle(tool)} />
                    <Typography color={Color.NAVY} size={TypographySize.SMALL}>
                      {TOOL_DISPLAY_NAMES[tool]}
                    </Typography>
                  </ToggleContainer>
                </ToolTip>)}
            </ToolsContainer>
          </StyledDropdown>
          {showMarkdownToggle && <ToggleContainer>
              <Toggle checked={hasMarkdownFormatting} onToggle={setHasMarkdownFormatting} />
              <Typography color={Color.BLUE}>Markdown format</Typography>
            </ToggleContainer>}
        </ModelSelectAndToggles>}
    </PromptBarContainer>;
};
export default PromptBar;