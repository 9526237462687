/**
 * Users can only pass in 1 CSV if they want to use combined and row_by_row together.
 * If they pass in multiple CSV files with COMBINED and ROW_BY_ROW,
 * it is unclear what the expected behavior is
 * (do they want the CSVs combined, or individually using the other combined content at context?),
 * therefore for now we do not support that and will throw an error.
 *
 * If it is:
 *
 *  - COMBINED
 *
 *    - WHOLE:       All content is fed as context together, CSVs are treated as a single entity.
 *
 *    - ROW_BY_ROW:  All other content is fed as context together.
 *                   If exactly 1 CSV is provided, each CSV row request is made with all other
 *                   content as context.
 *  - PARALLEL
 *
 *    - WHOLE:       Each piece of content is processed individually in parallel,
 *                   CSVs are treated as a single entity.
 *
 *    - ROW_BY_ROW:  Each piece of content is processed individually in parallel,
 *                   CSVs are processed row by row without context.
 *
 * default will be COMBINED and WHOLE. So if there's no contentInput, it will be COMBINED and WHOLE.
 */

// TODO: Move these into another file somewhere (plz don't delete my nice comment above)
export enum ContentProcessingMode {
  COMBINED = 'COMBINED',
  PARALLEL = 'PARALLEL'
}

export enum CSVProcessingMode {
  ROW_BY_ROW = 'ROW_BY_ROW',
  WHOLE = 'WHOLE'
}

export interface ContentInput {
  contentIds: string[];
  contentProcessingMode: ContentProcessingMode;
  csvProcessingMode: CSVProcessingMode;
}
