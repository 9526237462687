import { useEffect, useState } from 'react';
import { DisplayedChatMessage, isResponseChatMessage } from '../Chat.types';

/**
 * This hook streams text character-by-character, rather than in larger chunks,
 * simulating a smooth typing effect.
 */
const useSmoothStreaming = ({
  chatMessage,
  onChange
}: {
  chatMessage: DisplayedChatMessage;
  onChange?: (text: string) => void;
}) => {
  const [text, setText] = useState('');

  // How far behind the current text length must be from the target text length before
  // it starts adding more than one character per frame
  const CATCH_UP_THRESHOLD = 15;
  // The rate the text catches up once it's determined that it's lagging by more than the threshold,
  // a smaller divisor results in adding more characters per frame
  const CATCH_UP_DIVISOR = 15;

  // Determine if the message should be streamed based on its properties
  const isStreaming = isResponseChatMessage(chatMessage) && chatMessage.isStreaming;
  useEffect(() => {
    let animationFrameId: number;

    /**
     * This function progressively updates the `text` state to simulate a typing effect.
     * It incrementally adds characters from `chatMessage.message` to the displayed `text`.
     *
     * - First checks if current length of `text` matches the full length of `chatMessage.message`.
     *   If they match, returns current `text` without changes, effectively stopping the animation.
     *
     * - If lengths do not match, it calculates the difference between the full message length and
     *   the current `text` length, adjusted by a predefined threshold (`CATCH_UP_THRESHOLD`). This
     *   threshold helps determine how quickly the `text` should "catch up" if it's lagging.
     *
     * - Based on the calculated difference, determines number of characters to add (`addition`).
     *   If difference is less than or equal to zero, it adds one character. Otherwise, it adds a
     *   number of characters based on difference divided by a divisor (`CATCH_UP_DIVISOR`), rounded
     *   up to ensure at least one character is added each time.
     *
     * - It updates the `text` state to a new substring of `chatMessage.message` that includes the
     *   additional characters, simulating typing by revealing more of the message over time.
     *
     * - The function schedules itself to be called again on the next animation frame using
     *   `requestAnimationFrame`. This method tells the browser to perform an animation and requests
     *   that the browser calls a specified function to update an animation before the next repaint.
     *   The method takes a callback as an argument to be invoked before the repaint, thus ensuring
     *   smooth animations by aligning with the display refresh rate of the browser.
     */
    const animateText = () => {
      setText(prevText => {
        // Stop updating when the full message is displayed
        if (prevText.length === chatMessage.message.length) {
          return prevText;
        }

        // Calculate the number of characters to add to catch up to the current message length
        const difference = chatMessage.message.length - prevText.length - CATCH_UP_THRESHOLD;
        const addition = difference <= 0 ? 1 : Math.ceil(difference / CATCH_UP_DIVISOR);
        const newText = chatMessage.message.slice(0, prevText.length + addition);
        onChange?.(newText);
        return newText;
      });
      // Schedule the next frame
      animationFrameId = requestAnimationFrame(animateText);
    };
    if (isStreaming) {
      animationFrameId = requestAnimationFrame(animateText);
    }

    // Clean up by cancelling the scheduled frame
    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [isStreaming, chatMessage.message]);
  return {
    text
  };
};
export default useSmoothStreaming;