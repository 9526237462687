import _styled from "styled-components";
import { Color, Icon, IconButton, IconButtonType, LoadingSpinner, LoadingSpinnerSize, Size } from '~/components/core';
const LoadingStopButtonContainer = _styled.div.withConfig({
  displayName: "LoadingStopButton__LoadingStopButtonContainer",
  componentId: "sc-1fq0mtn-0"
})({
  "position": "relative",
  "height": "2rem",
  "width": "2rem"
});
const LoadingSpinnerContainer = _styled.div.withConfig({
  displayName: "LoadingStopButton__LoadingSpinnerContainer",
  componentId: "sc-1fq0mtn-1"
})({
  "position": "absolute",
  "zIndex": "0",
  "height": "2rem",
  "width": "2rem"
});
const IconButtonContainer = _styled.div.withConfig({
  displayName: "LoadingStopButton__IconButtonContainer",
  componentId: "sc-1fq0mtn-2"
})({
  "position": "absolute",
  "zIndex": "10",
  "display": "flex",
  "height": "2rem",
  "width": "2rem",
  "alignItems": "center",
  "justifyContent": "center"
});
type LoadingStopButtonProps = {
  onStopClick: () => void;
  'data-id'?: string | undefined;
};
const LoadingStopButton = ({
  onStopClick,
  'data-id': dataId
}: LoadingStopButtonProps) => <LoadingStopButtonContainer>
    <IconButtonContainer>
      <IconButton color={Color.NAVY} data-id={dataId} icon={Icon.CLOSE} onClick={onStopClick} size={Size.SMALL} type={IconButtonType.SIMPLE} />
    </IconButtonContainer>
    <LoadingSpinnerContainer>
      <LoadingSpinner size={LoadingSpinnerSize.LARGE} />
    </LoadingSpinnerContainer>
  </LoadingStopButtonContainer>;
export default LoadingStopButton;