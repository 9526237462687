import _styled from "styled-components";
import { CardOpacity, CardPadding, CardSize } from './Card.constants';
const BASE_CARD_CONTAINER_SIZE_STYLES: Record<CardSize, TwStyle> = {
  [CardSize.FILL]: {
    "width": "100%",
    "borderTopLeftRadius": "1.5rem",
    "borderTopRightRadius": "1.5rem"
  },
  [CardSize.LARGE]: {
    "width": "540px",
    "borderRadius": "0.75rem",
    "@media (min-width: 1450px)": {
      "display": "flex"
    }
  },
  [CardSize.MEDIUM]: {
    "borderRadius": "0.75rem"
  },
  [CardSize.SMALL]: {
    "width": "200px",
    "borderRadius": "0.75rem",
    "@media (min-width: 1450px)": {
      "display": "flex"
    }
  },
  [CardSize.EXPANDED]: {
    "width": "100%",
    "borderTopLeftRadius": "1.5rem",
    "borderTopRightRadius": "1.5rem"
  }
};
const GRADIENT_CARD_CONTAINER_SIZE_STYLES: Record<CardSize, TwStyle> = {
  [CardSize.FILL]: {},
  [CardSize.LARGE]: {
    "&:hover": {
      "backgroundImage": "linear-gradient(to bottom, var(--tw-gradient-stops))",
      "--tw-gradient-from": "#8CAEBA var(--tw-gradient-from-position)",
      "--tw-gradient-to": "#4D7280 var(--tw-gradient-to-position)",
      "--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to)",
      "--tw-shadow": "0px 4px 20px 0px rgba(0, 0, 0, 0.10)",
      "--tw-shadow-colored": "0px 4px 20px 0px var(--tw-shadow-color)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
    }
  },
  [CardSize.MEDIUM]: {
    "&:hover": {
      "backgroundImage": "linear-gradient(to bottom, var(--tw-gradient-stops))",
      "--tw-gradient-from": "#8CAEBA var(--tw-gradient-from-position)",
      "--tw-gradient-to": "#4D7280 var(--tw-gradient-to-position)",
      "--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to)",
      "--tw-shadow": "0px 4px 20px 0px rgba(0, 0, 0, 0.10)",
      "--tw-shadow-colored": "0px 4px 20px 0px var(--tw-shadow-color)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
    }
  },
  [CardSize.SMALL]: {
    "&:hover": {
      "backgroundImage": "linear-gradient(to bottom, var(--tw-gradient-stops))",
      "--tw-gradient-from": "#8CAEBA var(--tw-gradient-from-position)",
      "--tw-gradient-to": "#4D7280 var(--tw-gradient-to-position)",
      "--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to)",
      "--tw-shadow": "0px 4px 20px 0px rgba(0, 0, 0, 0.10)",
      "--tw-shadow-colored": "0px 4px 20px 0px var(--tw-shadow-color)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
    }
  },
  [CardSize.EXPANDED]: {
    "--tw-shadow": "0px 4px 20px 0px rgba(0, 0, 0, 0.10)",
    "--tw-shadow-colored": "0px 4px 20px 0px var(--tw-shadow-color)",
    "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
  }
};
const DASHED_CARD_CONTAINER_SIZE_STYLES: Record<CardSize, TwStyle> = {
  [CardSize.FILL]: {
    "borderWidth": "1px",
    "borderStyle": "dashed",
    "borderColor": "rgb(9 90 124 / 0.2)",
    "--tw-bg-opacity": "0.05",
    "backgroundColor": "rgb(9 90 124 / var(--tw-bg-opacity))",
    "&:hover": {
      "borderColor": "rgb(9 90 124 / 0.8)",
      "--tw-bg-opacity": "0.1",
      "backgroundColor": "rgb(9 90 124 / var(--tw-bg-opacity))"
    }
  },
  [CardSize.LARGE]: {
    "borderWidth": "1px",
    "borderStyle": "dashed",
    "borderColor": "rgb(9 90 124 / 0.2)",
    "--tw-bg-opacity": "0.05",
    "backgroundColor": "rgb(9 90 124 / var(--tw-bg-opacity))",
    "&:hover": {
      "borderColor": "rgb(9 90 124 / 0.8)",
      "--tw-bg-opacity": "0.1",
      "backgroundColor": "rgb(9 90 124 / var(--tw-bg-opacity))"
    }
  },
  [CardSize.MEDIUM]: {
    "borderWidth": "1px",
    "borderStyle": "dashed",
    "borderColor": "rgb(9 90 124 / 0.2)",
    "--tw-bg-opacity": "0.05",
    "backgroundColor": "rgb(9 90 124 / var(--tw-bg-opacity))",
    "&:hover": {
      "borderColor": "rgb(9 90 124 / 0.8)",
      "--tw-bg-opacity": "0.1",
      "backgroundColor": "rgb(9 90 124 / var(--tw-bg-opacity))"
    }
  },
  [CardSize.SMALL]: {
    "borderWidth": "1px",
    "borderStyle": "dashed",
    "borderColor": "rgb(9 90 124 / 0.2)",
    "--tw-bg-opacity": "0.05",
    "backgroundColor": "rgb(9 90 124 / var(--tw-bg-opacity))",
    "&:hover": {
      "borderColor": "rgb(9 90 124 / 0.8)",
      "--tw-bg-opacity": "0.1",
      "backgroundColor": "rgb(9 90 124 / var(--tw-bg-opacity))"
    }
  },
  [CardSize.EXPANDED]: {
    "borderWidth": "1px",
    "borderStyle": "dashed",
    "borderColor": "rgb(9 90 124 / 0.2)",
    "--tw-bg-opacity": "0.05",
    "backgroundColor": "rgb(9 90 124 / var(--tw-bg-opacity))",
    "&:hover": {
      "borderColor": "rgb(9 90 124 / 0.8)",
      "--tw-bg-opacity": "0.1",
      "backgroundColor": "rgb(9 90 124 / var(--tw-bg-opacity))"
    }
  }
};
const CARD_OPACITY_STYLES: Record<CardOpacity, TwStyle> = {
  [CardOpacity.SEMI_TRANSPARENT]: {
    "--tw-bg-opacity": "0.4"
  },
  [CardOpacity.OPAQUE]: {
    "--tw-bg-opacity": "1"
  }
};
export const CardContainer = _styled.div.withConfig({
  displayName: "Cardstyles__CardContainer",
  componentId: "sc-1izvetw-0"
})(({
  $dashed,
  $gradient,
  $resize,
  $size
}) => [{
  "position": "relative",
  "display": "flex",
  "flexDirection": "row-reverse"
}, BASE_CARD_CONTAINER_SIZE_STYLES[$size], $gradient && GRADIENT_CARD_CONTAINER_SIZE_STYLES[$size], $dashed && DASHED_CARD_CONTAINER_SIZE_STYLES[$size], $size === CardSize.MEDIUM && ($resize ? {
  "minWidth": "300px",
  "maxWidth": "440px"
} : {
  "width": "440px"
})]);
const CARD_MAIN_CONTAINER_SIZE_STYLES: Record<CardSize, TwStyle> = {
  [CardSize.FILL]: {
    "width": "100%",
    "borderBottomRightRadius": "0px",
    "borderBottomLeftRadius": "0px",
    "borderTopLeftRadius": "1.5rem",
    "borderTopRightRadius": "1.5rem",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(255 255 255 / var(--tw-bg-opacity))",
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "paddingTop": "1.5rem"
  },
  [CardSize.LARGE]: {
    "height": "176px",
    "width": "540px",
    "cursor": "pointer",
    "paddingLeft": "1.5rem",
    "paddingRight": "1.5rem",
    "paddingTop": "18px",
    "paddingBottom": "18px"
  },
  [CardSize.MEDIUM]: {
    "height": "176px",
    "cursor": "pointer",
    "paddingLeft": "1.5rem",
    "paddingRight": "1.5rem",
    "paddingTop": "18px",
    "paddingBottom": "18px"
  },
  [CardSize.SMALL]: {
    "height": "160px",
    "width": "200px",
    "cursor": "pointer",
    "paddingLeft": "0.75rem",
    "paddingRight": "0.75rem",
    "paddingTop": "8px",
    "paddingBottom": "8px"
  },
  [CardSize.EXPANDED]: {
    "width": "100%",
    "borderBottomRightRadius": "0px",
    "borderBottomLeftRadius": "0px",
    "borderTopLeftRadius": "1.5rem",
    "borderTopRightRadius": "1.5rem",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(255 255 255 / var(--tw-bg-opacity))",
    "paddingLeft": "4rem",
    "paddingRight": "4rem",
    "paddingTop": "1.5rem"
  }
};
const CARD_MAIN_CONTAINER_PADDING_STYLES: Record<CardPadding, TwStyle> = {
  [CardPadding.DEFAULT]: {},
  [CardPadding.WITH_LEFT_DRAWER]: {
    "paddingLeft": "1.25rem"
  }
};
export const CardMainContainer = _styled.div.withConfig({
  displayName: "Cardstyles__CardMainContainer",
  componentId: "sc-1izvetw-1"
})(({
  $dashed,
  $opacity,
  $padding,
  $resize,
  $size
}) => [{
  "display": "flex",
  "flexDirection": "column",
  "justifyContent": "space-between",
  "borderRadius": "0.75rem"
}, !$dashed && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(253 252 251 / var(--tw-bg-opacity))"
}, CARD_MAIN_CONTAINER_SIZE_STYLES[$size], CARD_OPACITY_STYLES[$opacity], CARD_MAIN_CONTAINER_PADDING_STYLES[$padding], $size === CardSize.MEDIUM && ($resize ? {
  "minWidth": "300px",
  "maxWidth": "440px",
  "flex": "1 1 0%"
} : {
  "width": "440px"
})]);
const CARD_CONTENT_SIZE_STYLES: Record<CardSize, TwStyle> = {
  [CardSize.FILL]: {
    "display": "flex",
    "flexGrow": "1"
  },
  [CardSize.LARGE]: {
    "maxHeight": "6rem"
  },
  [CardSize.MEDIUM]: {
    "maxHeight": "6rem"
  },
  [CardSize.SMALL]: {
    "maxHeight": "4rem"
  },
  [CardSize.EXPANDED]: {
    "display": "flex",
    "height": "100%",
    "flex": "1 1 0%",
    "flexDirection": "column",
    "justifyContent": "space-between"
  }
};
export const CardContent = _styled.div.withConfig({
  displayName: "Cardstyles__CardContent",
  componentId: "sc-1izvetw-2"
})(({
  $dashed,
  $size
}) => [{
  "overflow": "hidden"
}, CARD_CONTENT_SIZE_STYLES[$size], $dashed && {
  "height": "100%",
  "maxHeight": "100%",
  "overflowY": "auto"
}]);