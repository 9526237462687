import _styled from "styled-components";
import { forwardRef } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Typography, TypographySize, TypographyWeight } from '../core';
const ThinkingSkeleton = _styled.div.withConfig({
  displayName: "ChatLoadingIndicator__ThinkingSkeleton",
  componentId: "sc-gchfbv-0"
})(["", " background:linear-gradient( 90deg,rgba(177,172,235,0.7),rgba(177,172,235,0.4),rgba(177,172,235,0.7) );animation:skeletonAnimation 2.6s linear infinite;background-clip:text;background-size:200% 100%;-webkit-background-clip:text;-webkit-text-fill-color:transparent;@keyframes skeletonAnimation{0%{background-position:200% 0;}100%{background-position:-200% 0;}}"], {
  "position": "relative",
  "overflow": "hidden"
});
const ChatLoadingIndicator = forwardRef<HTMLDivElement, {
  isUsingTools: boolean;
  loadingText: string;
}>(({
  isUsingTools,
  loadingText
}, ref) => <div ref={ref}>
    {isUsingTools ? <ThinkingSkeleton>
        <Typography size={TypographySize.MEDIUM} weight={TypographyWeight.BOLD}>
          {loadingText}
        </Typography>
      </ThinkingSkeleton> : <SkeletonTheme highlightColor="rgba(197, 192, 255, 0.5)">
        <Skeleton count={3} duration={1.3} height={18} />
      </SkeletonTheme>}
  </div>);
export default ChatLoadingIndicator;