import { WorkstationScreen } from '../components/WorkstationScreen';
import { CHAT_ID_QUERY_PARAM } from '~/constants';
import { useURLParams } from '~/hooks';

/**
 * Uses the chatId from the URL as the component key to
 * reset the WorkstationScreen state when the chatId changes.
 *
 * This avoids bugs caused by stale state when the chatId is added, changed, or removed.
 * (E.g. chatMessages stored in state not clearing)
 *
 * https://react.dev/reference/react/useState#resetting-state-with-a-key
 */
const WorkstationScreenWithChatIdKey = () => {
  const params = useURLParams<{
    [CHAT_ID_QUERY_PARAM]?: string;
  }>();
  const {
    [CHAT_ID_QUERY_PARAM]: urlChatId
  } = params;
  return <WorkstationScreen key={`workstation-screen-${urlChatId || 'empty'}`} />;
};
export default WorkstationScreenWithChatIdKey;